.chat-assist {
    position: absolute;
    bottom: 105px;
    border: 1px solid #C5CAD5;
    border-radius: 10px;
    background-color: #ffffff;
    z-index: 1;
    width: 90%;
    left: 5%;
    max-height: 320px;
    overflow: hidden;
    padding: 10px 0;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
}

.chat-assist ul {
    padding-left: 14px;
    margin: 4px 8px 6px 4px;
}

.chat-assist .search-list {
    margin-bottom: 0;
}

.chat-assist .search-list svg {
    color: #FFFFFF;
}

.chat-assist .suggest-list {
    margin-top: 0;
}

.chat-assist .suggest-list svg {
    color: #323232;
    width: 14px !important;
    height: 14px !important;
    top: 8px !important;
}

.chat-assist .list-item {
    list-style: none;
    cursor: pointer;
    padding: 2px 0;
    font-size: 16px;
    margin-left: 2px;
    height: 30px;
    width: 96%;
    white-space: nowrap;
    border-bottom: 1px solid #E1E5E9;
    position: relative;
}

.chat-assist .list-item svg {
    position: absolute;
    top: 7px;
    width: 20px;
    height: 20px;
}

.chat-assist .list-item a,
.chat-assist .list-item span {
    width: 94%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    line-height: 26px;
}

.chat-assist .suggest-list .list-item {
    overflow: hidden;
    text-overflow: ellipsis;
}

.chat-assist .assist-actions {
    clear: both;
    overflow: hidden;
    margin: 0 4px;
    color: #1B7C53;
    font-size: 16px;
}

.chat-assist .assist-actions .assist-back {
    float: left;
    margin-left: 8px;
    cursor: pointer;
}

.chat-assist .assist-actions .assist-show-more {
    float: right;
    margin-right: 8px;
    cursor: pointer;
}

.chat-assist .assist-scroll-bar {
    background-color: #ffffff;
}

.chat-assist .assist-scroll-bar .scroll-view {
    overflow-y: scroll !important;
    overflow-x: hidden !important;
    margin-bottom: -2px !important;
}

.chat-assist .assist-scroll-bar .horizontal-scrollbar {
    display: none;
}

.chat-assist .assist-scroll-bar .vertical-scrollbar {
    position: absolute;
    width: 6px;
    right: 2px;
    bottom: 2px;
    top: 2px;
    border-radius: 3px;
    z-index: 0;
    height: -moz-fit-content;
}

.chat-assist .assist-scroll-bar .vertical-scrollbar-inner {
    background-color: #3E3C3C;
    border-radius: inherit;
}

.chat-assist .assist-content-container {
    height: 96%;
    width: 100%;
    bottom: 0;
    position: relative;
    top: 0;
}