.chat-tray {
    height: 100%;
    width: 100%;
    position: absolute;
    background: #ffffff;
    left: 0;
    border-radius: 8px;
    transition: left 0.5s;
    z-index: 2;
}

.chat-tray .chat-tray-header {
    height: 70px;
    background: #F0F2F5;
    border-radius: 8px 0 0 0;
    transition: border-radius 0.5s;
}

.chat-tray .chat-tray-header .chat-tray-close {
    position: absolute;
    top: 28px;
    left: 3%;
    cursor: pointer;
    font-size: 13px;
    font-weight: 600;
}

.chat-tray .chat-tray-header .chat-tray-close p {
    margin: 0;
}

.chat-tray .chat-tray-header .chat-tray-close .chat-tray-close-mobile {
    line-height: 30px;
    height: 30px;
    margin-top: -8px;
}

.chat-tray .chat-tray-header .chat-tray-close .chat-tray-close-mobile .chat-tray-close-icon-mobile {
    width: 10px;
    height: 20px;
    margin: 5px auto;
    display: inline-block;
    color: #323232;
    margin-right: 4px;
}

.chat-tray .chat-tray-header .chat-tray-home {
    position: absolute;
    top: 28px;
    right: 20px;
    cursor: pointer;
    font-size: 13px;
    font-weight: 600;
}

.chat-tray .chat-tray-close-header {
    border-radius: 8px 8px 0 0;
}

.chat-tray .chat-tray-close-footer {
    border-radius: 0 0 8px 8px !important;
}

.chat-tray .chat-tray-body {
    height: calc(100% - 70px);
    background: #F0F2F5;
    border-radius: 0 0 0 8px;
    transition: border-radius 0.5s;
}

.chat-tray .chat-tray-body .tray-scroll-bar {
    background-color: #F0F2F5;
    border-radius: 8px;
}

.chat-tray .chat-tray-body .tray-scroll-bar .scroll-view {
    overflow-y: scroll !important;
    overflow-x: hidden !important;
    margin-bottom: -6px !important;
}

.chat-tray .chat-tray-body .tray-scroll-bar .horizontal-scrollbar {
    display: none;
}

.chat-tray .chat-tray-body .tray-scroll-bar .vertical-scrollbar {
    position: absolute;
    width: 6px;
    right: 2px;
    bottom: 2px;
    top: 2px;
    border-radius: 3px;
    z-index: 0;
    height: -moz-fit-content;
}

.chat-tray .chat-tray-body .tray-scroll-bar .vertical-scrollbar-inner {
    background-color: #3E3C3C;
    border-radius: inherit;
}

.chat-tray .chat-tray-body .tray-content-container {
    height: 100%;
    width: 100%;
    bottom: 0;
    position: relative;
    top: 0;
}

.chat-tray .chat-tray-body .tray-content-container .hide-spinner,
.chat-tray .chat-tray-body .tray-content-container .hide-iframe {
    display: none;
}

.chat-tray .chat-tray-body .tray-content-container .tray-spinner {
    margin: 50px;
}

.chat-tray .chat-tray-body .tray-content-container .tray-question-link {
    display: block;
    height: 32px;
    color: #4682B4;
    text-decoration: underline;
    margin-left: 8px;
}

.chat-tray .chat-tray-body .tray-content-container .tray-notice {
    display: block;
    text-align: center;
    padding: 4px 10px !important;
}

.chat-tray .chat-tray-body .tray-content-container .data-rate {
    display: block;
    color: #00A45F;
    text-decoration: underline;
    cursor: pointer;
}

.chat-tray .chat-tray-body .tray-content-container .link-button-green {
    display: block;
    height: 44px;
    width: 88%;
    text-align: center;
    background-color: #1B7C53;
    color: #FFFFFF;
    line-height: 44px;
    margin: 10px auto;
    font-size: 16px;
    border-radius: 22px;
    font-weight: 500;
    text-decoration: none;
}

.chat-tray .chat-tray-body .tray-content-container .link-text {
    display: block;
    height: 44px;
    width: 88%;
    text-align: center;
    line-height: 44px;
    margin: 10px auto;
    font-size: 16px;
    color: black;
}

.chat-tray .chat-tray-body .tray-content-container .link-button-white {
    display: block;
    height: 44px;
    width: 88%;
    text-align: center;
    background-color: #FFFFFF;
    color: #5A6376;
    line-height: 44px;
    margin: 10px auto;
    font-size: 16px;
    border-radius: 22px;
    border: 1px solid #5A6376;
    text-decoration: none;
}

.chat-tray .chat-tray-body .tray-content-container .tray-embed-html {
    height: 100%;
    width: 100%;
    border: none;
}

.chat-tray .chat-tray-body .content-list {
    padding: 1% 2%;
}

.chat-tray .chat-tray-body .content-list .content-group {
    margin: 10px;
    border: 1px solid #8C96A8;
    border-radius: 4px;
    overflow: hidden;
}

.chat-tray .chat-tray-body .content-list .icon--extra-small {
    width: 16px;
    height: 16px;
}

.chat-tray .chat-tray-body .content-list .list-title {
    width: 100%;
    height: 44px;
    overflow: hidden;
    clear: both;
    cursor: pointer;
}

.chat-tray .chat-tray-body .content-list .list-title-detail {
    float: left;
    margin-left: 10px;
    line-height: 44px;
    font-size: 16px;
}

.chat-tray .chat-tray-body .content-list .list-title-chevron {
    float: right;
    margin: 14px 12px;
    cursor: pointer;
}

.chat-tray .chat-tray-body .content-list .show {
    display: block;
}

.chat-tray .chat-tray-body .content-list .hide {
    display: none;
}

.chat-tray .chat-tray-body .content-list .list-detail {
    padding: 0;
    max-height: 0;
}

.chat-tray .chat-tray-body .content-list .list-detail p {
    line-height: 20px;
    font-size: 16px;
    padding: 10px;
    margin: 0;
}

.chat-tray .chat-tray-body .content-list .expanded .list-detail {
    border-top: 1px solid #8C96A8;
    max-height: 600px;
    transition: max-height 0.5s ease-in;
}

.chat-tray .html-in-tray .tray-scroll-bar .vertical-scrollbar {
    display: none;
}

.chat-tray-open {
    border-radius: 8px 0 0 8px;
}

@media only screen and (max-width: 767px) {
    .chat-tray {
        bottom: 0 !important;
        height: 100% !important;
        max-height: 100%;
        right: 0;
        width: 100%;
        z-index: 2;
        border: none;
        border-radius: 8px;
    }

    .chat-tray .chat-tray-header {
        border-radius: 8px 8px 0 0;
    }
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

@keyframes agentIconWiggle {
    0% {
        transform: translate(1px, 1px) rotate(0deg);
    }

    5% {
        transform: translate(0px, -2px) rotate(-1deg);
    }

    10% {
        transform: translate(-1px, 0px) rotate(1deg);
    }

    15% {
        transform: translate(2px, 2px) rotate(0deg);
    }

    20% {
        transform: translate(1px, -1px) rotate(1deg);
    }

    25% {
        transform: translate(0px, 2px) rotate(-1deg);
    }

    30% {
        transform: translate(-1px, 1px) rotate(0deg);
    }

    35% {
        transform: translate(2px, 1px) rotate(-1deg);
    }

    40% {
        transform: translate(-1px, -1px) rotate(1deg);
    }

    45% {
        transform: translate(1px, 2px) rotate(0deg);
    }

    50% {
        transform: translate(0px, -2px) rotate(-1deg);
    }
}